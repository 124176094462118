$(document).on('turbolinks:load', function() {
    
    $('#rt_tf').change(function () {
        if(this.value === "static") {
            $("#fsu").css("display", "none");
            $('#intent_fulfilment_url').val("");
        } else if(this.value === "dynamic") {
            $("#fsu").css("display", "block");
        }
    });

    $('#cat_tf').change(function () {
        console.log('dropdown value', this.value);
        console.log('dropdown index', this.selectedIndex);
        if(this.selectedIndex == 0) {
            showAll();
            return;
        }
        for (i = 0; i < window.intent_total; i++) {
            let current = document.getElementById("item"+i);
            if(window.intent_data[i].context_id == window.contexts_id_data[this.selectedIndex]) {
                current.style.display = "";
            } else {
                current.style.display = "none";
            }        
        }
    });

    function showAll() {
        for (i = 0; i < window.intent_total; i++) {
            let current = document.getElementById("item"+i);
            current.style.display = "";
        }
    }
    
    $( "#card-activate-button" ).click(function() {
        $("#card-activate-button").css("display", "none");
        $("#card-activate-button-spacer").css("display", "none");
        $("#card-holder").css("display", "block");
    });

    $( "#card-deactivate-button" ).click(function() {
        $("#card-holder").css("display", "none");
        $("#card-activate-button").css("display", "block");
        $("#card-activate-button-spacer").css("display", "block");
    });

    $('#check_box_all').change(function(){
        if($(this).prop('checked')){
            $('tbody tr td input[type="checkbox"]').each(function(evt){
                if($('#item' + evt).is(":visible")) {
                    $(this).prop('checked', true);         
                }
            });
            $("#delete_check_btn_holder").removeClass("hidden");
            $("#delete_check_btn_holder").addClass("visible");
            $("#append_dropdown_holder").removeClass("hidden");
            $("#append_dropdown_holder").addClass("visible");

            $("#select_dropdown_holder").removeClass("visible");
            $("#select_dropdown_holder").addClass("hidden");

            // $("#table-div").removeClass("my-6");
            // $("#table-div").addClass("my-0");
        }else{
            $('tbody tr td input[type="checkbox"]').each(function(){
                $(this).prop('checked', false);
            });
            $("#delete_check_btn_holder").removeClass("visible");
            $("#delete_check_btn_holder").addClass("hidden");
            $("#append_dropdown_holder").removeClass("visible");
            $("#append_dropdown_holder").addClass("hidden");

            $("#select_dropdown_holder").removeClass("hidden");
            $("#select_dropdown_holder").addClass("visible");

            // $("#table-div").removeClass("my-0");
            // $("#table-div").addClass("my-6");
        }
        const selectedIds = [];
        $('tbody tr td input[type="checkbox"]').each(function(evt) {
            if($('#item' + evt).is(":visible")) {
                if(this.value != "") {
                    console.log('adding', this.value);
                    selectedIds.push(this.value);
                }
            }
        });
        $("#selected_ids_confirm").val(selectedIds);
    });

    $("input[type=checkbox]").each(function(index) {
        $(this).on("click", function(){
            if($("input:checked").length > 0) {
                $("#delete_check_btn_holder").removeClass("hidden");
                $("#append_dropdown_holder").removeClass("hidden");
                // $("#table-div").removeClass("my-6");
                $("#delete_check_btn_holder").addClass("visible");
                $("#append_dropdown_holder").addClass("visible");
                // $("#table-div").addClass("my-0");
                $("#select_dropdown_holder").removeClass("visible");
                $("#select_dropdown_holder").addClass("hidden");
            }
            else {
                $("#delete_check_btn_holder").removeClass("visible");
                $("#append_dropdown_holder").removeClass("visible");
                // $("#table-div").removeClass("my-0");
                $("#delete_check_btn_holder").addClass("hidden");
                $("#append_dropdown_holder").addClass("hidden");
                // $("#table-div").addClass("my-6");
                $("#select_dropdown_holder").removeClass("hidden");
                $("#select_dropdown_holder").addClass("visible");
            }
            const selectedIds = [];
            $( "input:checked" ).each(function() {
                if(this.value != "") {
                    selectedIds.push(this.value);
                }
            });
            $("#selected_ids_confirm").val(selectedIds);
        });
    });

    $(document).ready(function() {
        window.activateBestInPlace();
        showAllHandoffs();
    });

    // Handoff Dropdowns

    $('#dep_filter_sel').on('change', (evt) => {

        if(evt.target.value == "") {
            showAllHandoffs();
            return;
        }
        for (i = 0; i < window.handoff_total; i++) {
            let current = document.getElementById("item"+i);
            if(window.handoff_data[i].team_id == evt.target.value) {
                current.style.display = "";
            } else {
                current.style.display = "none";
            }        
        }
    });

    $('#action_filter_sel').on('change', (evt) => {

        if(evt.target.value == "") {
            console.log('Nothing selected');
            return;
        } else if(evt.target.value == "Delete selected") {
            let selectedId = getSelectedIds();
            const $el = $(this);
            const response = confirm($el.data('confirm') || 'Are you sure?');
            if(response) {
                $("#selected_ids_confirm").val(selectedId);
                $.ajax({
                    url:  '/unresolved/delete',
                    type: 'POST',
                    data: {_method: 'post', selected_ids: getSelectedIds() }
                  })
                  .then(function() {
                  })
                  .fail(function() {
                  })
            }
        } else if(evt.target.value == "Link to existing question") {
            console.log('Make link to exisiting request via ajax to the route', getSelectedIds());
            $("#selected_ids_confirm").val(getSelectedIds());
            window.location.href = '/unresolved/utterance/append/multiple?id='+ getSelectedIds();
        }
 
    });

    function getSelectedIds() {
        const selectedIds = [];
        $( "input:checked" ).each(function() {
            if(this.value != "") {
                selectedIds.push(this.value);
            }
        });
        return selectedIds;
    }

    function showAllHandoffs() {
        for (i = 0; i < window.handoff_total; i++) {
            let current = document.getElementById("item"+i);
            if(window.handoff_data[i].team_id == null) {
                current.style.display = "";
            } else {
                current.style.display = "none";
            }
        }
    }

    $('#dep_sel').on('change', (evt) => {
        if(evt.target.value == "") {
            return;
        }
        const selectedIds = [];
        $( "input:checked" ).each(function() {
            if(this.value != "") {
                selectedIds.push(this.value);
            }
        });
        let url = "";
        if(window._isLocal) {
            url = 'https://intents-service.voice-first.agency/unresolved/append/section';
        } else {
            url = 'http://localhost:3000/unresolved/append/section';
        }
        const requestData = { 'team_id': evt.target.value, 'ids': selectedIds };
        $.post(url, requestData, function(data, status, xhr) {   // success callback function
            location.reload();
        },'json');

    });
    
});